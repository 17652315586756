import React from "react";

const About = () => {
  return (
    <>
      <section className="fullscreen background-image">
        <div className="container">
          <div className="container-fullscreen">
            <div className="text-middle text-right">
              {/* <div data-animate="fadeInDown" data-animate-delay="500"> */}
              <img style={{width: '100%'}} src="./imagens/logo.png" alt="" />
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-t-150 background-image bg-quem-sou"
        // style="background-image: url(imagens/bg.jpg)"
        id="quemsoueu"
      >
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-7" data-animate="fadeIn"> */}
            <div className="col-lg-7">
              <h2 className="text-medium">SOBRE NÓS</h2>
              <p>
                Somos uma Empresa de Tecnologia fundada em 2019 focada no desenvolvimento
                Mobile pra Jogos e Apps. Tem algum projeto incrível para ser desenvolvido? Entre
                em contato conosco!
              </p>
              <p>
                Ou veja nossos últimos trabalhos e faça parte desta comunidade
                que vem crescendo cada vez mais!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="box-fancy section-fullwidth text-light">
        <div className="row">
          <div className="col-lg-4 boxConceito">
            <h1 className="text-lg text-uppercase">01.</h1>
            <h3>CONCEITO</h3>
            <span>
              Quando aquela ideia magnífica surge, nós não deixamos ela no fundo
              do baú. Partimos para as anotações e criações de fluxos e
              diagramas, para verificação de viabilidade. Caso a ideia seja
              aprovada, é inicado o processo de desenvolvimento.
            </span>
          </div>

          <div className="col-lg-4 boxDesenvolvimento">
            <h1 className="text-lg text-uppercase">02.</h1>
            <h3>DESENVOLVIMENTO</h3>
            <span>
              Chegou a hora de escolher como fazer. É feita uma análise
              municiosa sobre o projeto, e quais funcionalidades deverá ter na
              versão Beta. Para nós, qualidade é essencial. E, não menos
              importante, a fase de testes é primordial para um bom projeto.
            </span>
          </div>

          <div className="col-lg-4 boxTestes">
            <h1 className="text-lg text-uppercase">03.</h1>
            <h3>TESTES</h3>
            <span>
              Uma das partes mais importantes do projeto. Talvez até a mais
              importante, e a que é deixada de lado por muitos. Os testes são
              essenciais para garantir a qualidade do projeto, e por
              consequência, a fidelidade do usuário.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
