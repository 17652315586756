import React, { useEffect, useState } from "react";

import Header from "./Header";
import About from "./About";
import Work from "./Work";
import Who from "./Who";
import Footer from "./Footer";

function App() {
  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = "/js/jquery.js";
    // document.body.appendChild(script);

    // const script1 = document.createElement("script");
    // script1.src = "/js/plugins.js";
    // document.body.appendChild(script1);

    // const script2 = document.createElement("script");
    // script2.src = "/js/functions.js";
    // document.body.appendChild(script2);

    // return () => {
    //   document.body.removeChild(script);
    //   document.body.removeChild(script1);
    //   document.body.removeChild(script2);
    // };
  }, []);

  const [who] = useState([
    {
      id: 1,
      name: "Edson Freire",
      position: "CEO",
      avatar:
        "https://secure.gravatar.com/avatar/23d3ea64f1637602ca747af6fec8b8af?size=80",
      instagram: "https://www.instagram.com/edsonmfreire/",
      linkedin: "https://www.linkedin.com/in/edson-freire/",
    },
    {
      id: 2,
      name: "Larissa Texeira",
      position: "Manager",
      avatar:
        "https://secure.gravatar.com/avatar/23d3ea64f1637602ca747af6fec8b8af?size=80",
      instagram: "https://www.instagram.com/_laryteixeira/",
      linkedin: "https://www.linkedin.com/in/larissa-teixeira-92aa9695",
    },
    {
      id: 3,
      name: "Caroline Oliveira",
      position: "QA Specialist",
      avatar: "https://secure.gravatar.com/avatar/23d3ea64f1637602ca747af6fec8b8af?size=80",
      instagram: "https://www.instagram.com/carolzinha.o/",
      linkedin: "",
    },
    {
      id: 4,
      name: "Allan Oliveira",
      position: "Enginner",
      avatar: "https://secure.gravatar.com/avatar/23d3ea64f1637602ca747af6fec8b8af?size=80",
      instagram: "https://www.instagram.com/allan_freire/",
      linkedin: "",
    },
  ]);

  return (
    <div className="body-inner">
      <Header />
      <About />
      <Work />
      <Who data={who} />
      <Footer />
    </div>
  );
}

export default App;
