import React from "react";

const Work = () => {
  return (
    <section className="p-b-0" id="ultimostrabalhos">
      <div className="container">
        <div className="text-center">
          <h3 className="text-medium m-b-5">NOSSO TRABALHO</h3>
        </div>
      </div>

      <div className="tab">
        <input type="radio" name="tabs" id="tab1" defaultChecked />
        <label htmlFor="tab1">Apps</label>

        <input type="radio" name="tabs" id="tab2" />
        <label htmlFor="tab2">Games</label>

        <input type="radio" name="tabs" id="tab3" />
        <label htmlFor="tab3">Outros</label>
        {/* <input type="radio" name="tabs" id="tab2" />
        <label htmlFor="tab2">Jogos</label> */}

        <div className="tabs">
          <div className="content">
            <div className="portfolio">
              <div
                id="portfolio"
                className="grid-layout portfolio-4-columns"
                data-margin="0"
              >
                <div className="portfolio-item overlay-dark ct-photography ct-media ct-branding ct-Media">                  
                  <div className="portfolio-item-wrap">
                    <div className="portfolio-image">
                      <img src="./imagens/chamada_diary.png" alt="" />
                    </div>
                    <div className="portfolio-description">
                      <a href="https://mydiary.digital" target="_blank" rel="noreferrer">
                        <h3>My Diary</h3>
                      </a>
                      <div>
                        <a href="https://play.google.com/store/apps/details?id=ekshat.diary" target="_blank" rel="noreferrer">
                          <span>Android</span>
                        </a>
                        &nbsp;&nbsp;
                        <span>IOS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portfolio-item overlay-dark ct-photography ct-media ct-branding ct-Media">
                  <div className="portfolio-item-wrap">
                    <div className="portfolio-image">
                      <img src="./imagens/chamada_message.png" alt="" />
                    </div>
                    <div className="portfolio-description">
                      <a href="https://messageshare.com.br" target="_blank" rel="noreferrer">
                        <h3>Message Share</h3>
                      </a>
                      <div>
                        <a href="https://play.google.com/store/apps/details?id=ekshat.messageshare" target="_blank" rel="noreferrer">
                          <span>Android</span>
                        </a>
                        &nbsp;&nbsp;
                        <span>IOS</span>
                      </div>
                    </div>
                  </div>
                </div>                
                <div className="portfolio-item overlay-dark ct-photography ct-media ct-branding ct-Media">
                  <div className="portfolio-item-wrap">
                    <div className="portfolio-image">
                      <img src="./imagens/chamada_scapets.jpg" alt="" />
                    </div>
                    <div className="portfolio-description">
                      <a href="https://scapets.com.br" target="_blank" rel="noreferrer">
                        <h3>Scapets</h3>
                      </a>
                      <div>
                        <a href="https://play.google.com/store/apps/details?id=br.com.scapets" target="_blank" rel="noreferrer">
                          <span>Android</span>
                        </a>
                        &nbsp;&nbsp;
                        <span>IOS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portfolio-item overlay-dark ct-photography ct-media ct-branding ct-Media">
                  <div className="portfolio-item-wrap">
                    <div className="portfolio-image">
                      <img src="./imagens/chamada_personalteam.png" alt="" />
                    </div>
                    <div className="portfolio-description">
                      <a href="https://personalteam.com.br" target="_blank" rel="noreferrer">
                        <h3>Personal TEAM</h3>
                      </a>
                      <div>
                        <a href="https://play.google.com/store/apps/details?id=ekshat.personalteam" target="_blank" rel="noreferrer">
                          <span>Android</span>
                        </a>
                        &nbsp;&nbsp;
                        <span>IOS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="portfolio">
              <div
                id="portfolio"
                className="grid-layout portfolio-4-columns"
                data-margin="0"
              >
                <div className="portfolio-item overlay-dark ct-photography ct-media ct-branding ct-Media">
                  <div className="portfolio-item-wrap">
                    <div className="portfolio-image">
                      <img src="./imagens/chamada_fla_stuff.png" alt="" />
                    </div>
                    <div className="portfolio-description">
                      <a href="https://flastuff.ekshat.com.br" target="_blank" rel="noreferrer">
                        <h3>Fla Stuff</h3>
                      </a>
                      <div>
                        <span>EM BREVE</span>
                        {/* <a href="https://play.google.com/store/apps/details?id=ekshat.flastuff" target="_blank" rel="noreferrer">
                          <span>Android</span>
                        </a>
                        &nbsp;&nbsp;
                        <span>IOS</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portfolio-item overlay-dark ct-photography ct-media ct-branding ct-Media">
                  <div className="portfolio-item-wrap">
                    <div className="portfolio-image">
                      <img src="./imagens/chamada_retro_server.png" alt="" />
                    </div>
                    <div className="portfolio-description">
                      <a href="https://server.ekshat.com.br" target="_blank" rel="noreferrer">
                        <h3>Retro Server</h3>
                      </a>
                      <div>
                        <span>EM BREVE</span>
                        {/* <a href="https://play.google.com/store/apps/details?id=ekshat.flastuff" target="_blank" rel="noreferrer">
                          <span>Android</span>
                        </a>
                        &nbsp;&nbsp;
                        <span>IOS</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="portfolio">
              <div
                id="portfolio"
                className="grid-layout portfolio-4-columns"
                data-margin="0"
              >
                <div className="portfolio-item overlay-dark ct-photography ct-media ct-branding ct-Media">
                  <div className="portfolio-item-wrap">
                    <div className="portfolio-image">
                      <img src="./imagens/chamada_ekshatcoin.png" alt="" />
                    </div>
                    <div className="portfolio-description">
                      <a href="https://ekshatcoin.com" target="_blank" rel="noreferrer">
                        <h3>Ekshat Coin</h3>
                      </a>
                      {/* <div>
                        <a href="https://play.google.com/store/apps/details?id=br.com.scapets" target="_blank" rel="noreferrer">
                          <span>Android</span>
                        </a>
                        &nbsp;&nbsp;
                        <span>IOS</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
