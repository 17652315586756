import React from "react";

const Header = () => {
  return (
    <header id="header" data-transparent="true" data-fullwidth="true">
      <div className="header-inner">
        <div className="container">
          <div id="mainMenu-trigger">
            <a className="lines-button x" href="/#">
              <span className="lines"></span>
            </a>
          </div>

          <div id="mainMenu">
            <div className="container">
              <nav>
                <ul>
                  <li>
                    <a href="/#">Home</a>
                  </li>
                  <li>
                    <a href="#quemsoueu">Sobre</a>
                  </li>
                  <li>
                    <a href="#ultimostrabalhos">Trabalhos</a>
                  </li>
                  <li>
                    <a href="#quemsomos">Quem somos</a>
                  </li>
                  <li>
                    <a className="link" href="#contato">
                      Contato
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
