import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="copyright-content">
        <div className="container">
          <div className="copyright-text text-rodape text-left">
            &copy; 2021 Ekshat, heaven at technology - Todos os direitos
            reservados.
          </div>
          <div className="copyright-text links-rodape text-rodape text-right">
            {/* <div className="whatsapp">
              <a
                href="https://api.whatsapp.com/send?phone=5579991208279"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="imagens/whatsapp.png"
                  alt="Whatsapp"
                  title="Whatsapp"
                />
              </a>
            </div> */}
            <div className="linkedIn">
              <a href="https://twitter.com/Ekshat3" target="_blank" rel="noreferrer">
                <img src="imagens/twitter.png" alt="Twitter" title="twitter" />
              </a>
            </div>
            <div className="linkedIn">
              <a href="https://www.instagram.com/ekshat_/" target="_blank" rel="noreferrer">
                <img
                  src="imagens/instagram.png"
                  alt="Instagram"
                  title="instagram"
                />
              </a>
            </div>
            <div className="github">
              <a
                href="https://www.youtube.com/channel/UCXdTSsWVxCFNB13g7ky1uWQ"
                target="_blank"
                rel="noreferrer"
              >
                <img src="imagens/youtube.png" alt="YouTube" title="youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
