import React from "react";

const Who = (props) => {
  const { data } = props;

  return (
    <section id="quemsomos">
      <div className="container">
        <div className="text-center">
          <h3 className="text-medium m-b-5">QUEM SOMOS</h3>
        </div>
        <hr className="space" />
        <div
          className="equalize testimonial testimonial-box"
          data-margin="10"
          data-arrows="false"
          data-items="3"
        >
          {data &&
            data.map((item) => {
              return (
                <div key={item.id} className="testimonial-item">
                  <img src={item.avatar} alt="" />
                  <span>{item.name}</span>
                  <span>{item.position}</span>

                  <div className="social">
                    <div className="network">
                      <a href={item.instagram} target="_blank" rel="noreferrer">
                        <img
                          src="imagens/instagram.png"
                          alt="Instagram"
                          title="instagram"
                        />
                      </a>
                    </div>
                    <div className="network">
                      {item.linkedin !== '' ? (
                        <a href={item.linkedin} target="_blank" rel="noreferrer">
                            <img 
                            src="imagens/linkedin.png"
                            alt="LinkedIn"
                            title="linkedin"
                          />
                        </a>
                      ) : (
                        <img 
                          src="imagens/linkedin.png"
                          alt="Linkedin"
                          title="linkedin"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="triangle-divider-bottom"></div>
    </section>
  );
};

export default Who;
